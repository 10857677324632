import React, { useEffect, useState } from "react";

// vendor
import { FiBook, FiGrid, FiMonitor, FiSmartphone, FiTablet } from "react-icons/fi";
import { RiTShirt2Line } from "react-icons/ri";
import { BiChalkboard } from "react-icons/bi";
import { navigate } from "gatsby";
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState, } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

// core
import elementsConfiguration from '@mattevans-dev/editsquare.core/utils/elementsConfiguration';

// components
import MockupHeader from "../components/mockup/header";
import MockupContainer from "../components/mockup/mockupContainer";
import { mockupsLibrary } from "../library/mockupsLibrary.mjs";
import { projectConfigurationAtom, selectedSceneElementsSelector } from "../utils/atomStore";
import MockupTemplates from "../components/mockup/mockupTemplates";

const SelectTemplate = () => {
    const canvasElements = useRecoilValue( selectedSceneElementsSelector );
    const [projectConfiguration, setProjectConfiguration] = useRecoilState( projectConfigurationAtom );
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    
    // only run on init. render
    useEffect(() => {
        // set default state if template already selected in canvaselements
        if(Array.isArray(canvasElements) && canvasElements.length == 1) {
            const uid = canvasElements[0].templateId;
            const template = _.find(mockupsLibrary, {uid: uid});
            setSelectedTemplate(template);
            setSelectedCategory(template.category);
        }
    }, [])

    const categorySubmit = (category) => {
        setSelectedCategory(category);
    }

    // replace the project config elements with the selected template
    const templateSubmit = (selectedTemplateVideo) => {
        // get existing config
        let placeholderArgs = {};
        if(canvasElements.length == 1) {
            const mockupElem = canvasElements[0];
            placeholderArgs = mockupElem?.placeholder ?? {};
        }
        // retrieve element config
        const elementConfig = _.find(elementsConfiguration, { uid: selectedTemplateVideo.elementType });
        // merge inline config, element config and library config
        let newUid = uuidv4();
        let placeholder = _.merge(
            {},
            placeholderArgs,
            elementConfig.defaultArgs?.placeholder,
            selectedTemplateVideo.defaultArgs?.placeholder
        );
        let args = _.merge(
            {
                elementType: selectedTemplateVideo.elementType,
                left: 0,
                top: 0,
                uid: newUid,
                templateId: selectedTemplateVideo.uid,
                placeholder: placeholder
            },
            elementConfig.defaultArgs || {},
            selectedTemplateVideo.defaultArgs || {}
        );
        // force update of perspective array as we're only doing a shallow merge.

        // replace canvas elements with new args
        // and update project duration
        const projectConfig = _.merge(
            {}, 
            projectConfiguration,
            { 
                duration: args.duration,
                selectedTemplateId: selectedTemplateVideo.id,
                scenes: [
                    {
                        name: 'Mockup',
                        elements: [ args ]
                    }
                ]
            }
        );
        setProjectConfiguration(projectConfig);
        // navigate to next page
        navigate("/choose-media");
    }

    return (
        <MockupContainer>
            <form onSubmit={(e) => { e.preventDefault(); templateSubmit(selectedTemplate); }}>
                <MockupHeader />
                <div className="flex justify-center items-center flex-col max-w-screen-2xl mx-auto px-10 pt-8 mb-5">
                    <div className="w-full">
                        <div className="block w-full">
                            <h2 className="text-2xl font-dm font-bold mb-8">Browse and select a mockup template</h2>
                        </div>
                        <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-72 px-4">
                            <div className="text-xs text-gray-600 font-bold leading-none tracking-wide uppercase mb-2">Categories</div>
                            <nav className="space-y-0.5 mb-6">
                                <a href="#" onClick={(e) => { e.preventDefault(); categorySubmit(null); }} className={`border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 ${selectedCategory == null ? 'border-indigo-600 bg-indigo-700 text-indigo-700' : 'text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900'}`}>
                                    <FiGrid className="ml-1 mr-2.5 h-5 w-5" />
                                    View All
                                </a>
                                <a href="#" onClick={(e) => { e.preventDefault(); categorySubmit('Computer'); }} className={`border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 ${selectedCategory == 'Computer' ? 'border-2 border-indigo-600 bg-indigo-700 text-indigo-700' : 'text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900'}`}>
                                    <FiMonitor className="ml-1 mr-2.5 h-5 w-5 opacity-85" />
                                    Computer
                                </a>
                                <a href="#" onClick={(e) => { e.preventDefault(); categorySubmit('Mobile'); }} className={`border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 ${selectedCategory == 'Mobile' ? 'border-2 border-indigo-600 bg-indigo-700 text-indigo-700' : 'text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900'}`}>
                                    <FiSmartphone className="ml-1 mr-2.5 h-5 w-5 opacity-85" />
                                    Mobile Phone
                                </a>
                                <a href="#" onClick={(e) => { e.preventDefault(); categorySubmit('Tablet'); }} className={`border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 ${selectedCategory == 'Tablet' ? 'border-2 border-indigo-600 bg-indigo-700 text-indigo-700' : 'text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900'}`}>
                                    <FiTablet className="ml-1 mr-2.5 h-5 w-5 opacity-85" />
                                    Tablet
                                </a>
                                <div className={`opacity-35 justify-between border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900`}>
                                    <div className={'flex items-center'}>
                                        <BiChalkboard className="ml-1 mr-2.5 h-5 w-5 opacity-85" />
                                        Billboard 
                                    </div>
                                    <div className="text-xs bg-indigo-600 text-white px-2 rounded">coming soon</div>
                                </div>
                                <div className={`opacity-35 justify-between border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900`}>
                                    <div className={'flex items-center'}>
                                        <FiBook className="ml-1 mr-2.5 h-5 w-5 opacity-85" />
                                        Book 
                                    </div>
                                    <div className="text-xs bg-indigo-600 text-white px-2 rounded">coming soon</div>
                                </div>
                                <div className={`opacity-35 justify-between border-2 border-transparent bg-opacity-10 group rounded-md py-1.5 px-2 flex items-center text-base font-medium mb-1 text-gray-700 hover:bg-gray-400 hover:bg-opacity-50 hover:text-gray-900`}>
                                    <div className={'flex items-center'}>
                                        <RiTShirt2Line className="ml-1 mr-2.5 h-5 w-5 opacity-85" />
                                        Clothing 
                                    </div>
                                    <div className="text-xs bg-indigo-600 text-white px-2 rounded">coming soon</div>
                                </div>
                            </nav>
                        </div>
                            <div className="templateGrid overflow-y-auto w-full md:flex-1 px-4">
                                <div className="flex flex-wrap -mx-2">
                                    <MockupTemplates 
                                        category={selectedCategory} 
                                        selected={selectedTemplate?.uid} 
                                        onClick={(video) => { setSelectedTemplate(video); }}
                                        onKeyPressEnter={(video) => { templateSubmit(video); }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white shadow-top w-full">
                    <div className="flex items-center justify-end text-sm py-3 max-w-screen-2xl mx-auto px-10">
                        <button  className={`py-3 px-4 transition duration-150 hover:bg-indigo-600 bg-indigo-700 font-semibold rounded text-white ${!selectedTemplate ? 'btn-disabled' : ''}`}><span className="opacity-50 mr-1">Next:</span>Choose Media</button>
                    </div>
                </div>
            </form>
        </MockupContainer>
    )
}
export default SelectTemplate;